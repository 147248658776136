import React from "react"
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TourMenu from '../../components/TourMenu';
import { Helmet } from "react-helmet"
import {Link} from 'gatsby';

export default function Index() {

    return (

        <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>Feature tour - get started | Skillmeter</title>
        </Helmet>
                
        <Header />

        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
              Feature tour
            </h2>
            <p className="mt-6 text-lg font-normal text-gray-600 ">
            See how easy is to get started with Skillmeter
          </p>               
          </div>
  
          <div className="max-w-5xl mx-auto mt-10 sm:mt-20">
            <div className="flow-root">
            <div className="-my-8 divide-y divide-gray-200">
                <div className="py-8">
                  <div className="flex">
                    <div className="w-1/4">
                        <TourMenu option="1" />

                    </div>
                    <div className="w-3/4">
                      <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl ">
                      Let's get started!
                      </h2>               
                      <br /><br />       
                      Skillmeter is a powerful online testing platform that enables recruiters, trainers or educators to build tests to measure candidates' skills.
                      <br /><br />
                      This 9 step tour demonstrates how easy it is to start using Skillmeter for your organization.
                      <br /><br />
                      The first step is to sign up for a free trial. We offer a 14-day free trial on all plans.
                      <br /><br />
                      <Link to={'/pricing'} className="inline-flex items-center justify-center px-6 py-3 text-base font-bold bg-smcolor text-white rounded-xl focus:outline-none hover:bg-smcolor-dark  hover:text-white " role="button">
                        SIGN UP
                      </Link>

                      <br /><br />
                      <b>Questions?</b>
                      <br />
                      If you have any questions about Skillmeter or the sign-up process, feel free to contact us by email at <a href="mailto:support@skillmeter.com">support@skillmeter.com</a> and we'll get right back to you.

                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </section>
  
        <Footer />
      </>
    )
}